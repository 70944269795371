/* Common styles for registration form */

.ui-registration-form .subheading {
    font-size: var(--font-size-h6);
    font-weight: bold;
    padding: 10px 0 6px;
    clear: both;
}

.ui-registration-form .w-input-list-item {
    list-style: none;
}

.ui-registration-form .w-buttons {
    margin-top: 10px;
}

.w-external-login-buttons {
    font-size: 2rem;
}

.ui-registration-form .w-activation-type {
    margin: 15px 0 10px;
}

.ui-registration-form .w-privacy-policy {
    margin: 15px 0;
}

.ui-registration-form .w-parental-consent {
    margin: 40px 0 15px;
}

.w-parental-consent a {
    text-decoration: underline;
}

.system-maintenance {
    background-color: white;
    padding: 5px;
    border-radius: 10px;
}


/* registration sponsor vip */
.ui-registration-box {
    text-align: center;
}

.ui-registration-box__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    margin-bottom: 0;
}

.ui-registration-box__header .v-icon-info {
    font-size:80px;
    color: var(--info-main)
}

.ui-registration-box__pbs-info-text {
    font-size: var(--font-size-body1);
    line-height: 1.43;
    margin-bottom: 0;
    text-align: center;
}

.ui-registration-box__header .headline {
    font-size: var(--font-size-heading1);
    line-height: 1.14;
    color: var(--grey-700);
}

.disabled-registration-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255,255,255,0.5);
    z-index: 10;
}

.ui-registration-box__button {
    width: 258px;
    max-width: 100%;
    background: transparent;
    border: 2px solid var(--grey-900);
    color: var(--text-primary);
    margin-top: 30px;
}

.ui-registration-box__button:not([disabled]):focus,
.ui-registration-box__button:not([disabled]):hover {
    background: transparent;
    color: var(--text-primary);
}

@media only screen and (max-width: 767px) {
    .ui-registration-box__pbs-info-text {
        font-size: var(--font-size-body1);
    }
    
    .ui-registration-box__header .headline {
        font-size: var(--font-size-h4);
    }

    .ui-registration-box__button {
        width: 330px;
        max-width: 100%;
    }
}

.ui-registration-form .approvals-section-toggle {
    font-size: var(--font-size-caption);
    color: #555555;
    font-weight: bold;
    margin-top: 10px;
}

    .ui-registration-form .approvals-section-toggle .v-icon-arrow-down-thin::before {
        font-size: var(--font-size-icon2);
        padding-left: 10px;
        vertical-align: bottom;
    }

    .ui-registration-form .approvals-section-toggle .v-icon-arrow-up-thin::before {
        font-size: var(--font-size-icon2);
        padding-left: 10px;
        vertical-align: bottom;
    }

    .ui-registration-form .approvals-section-wrapper {
        margin-bottom: 20px;
    }

/* Fix for Bug#528166 - Unification of font sizes for labels on self-registration forms. */
.ui-registration-form .document-upload__header > label,
.ui-registration-form .document-upload__expiration-label {
    font-size: var(--font-size-caption);
}

.w-control.g-half {
    clear: none;
}

.w-control.g-half:nth-of-type(2n) {
    margin-right: 0;
}

.g-half .k-dropdown-datepicker {
    width: auto;
}

.terms-conditions-reseller {
    margin: 10px 0;
}

.ui-registration-form .terms-conditions-reseller {
    padding-left: 37px;
}

.terms-conditions-reseller ul {
    display: block;
    width: 100%;
    margin: 0;
    padding: 10px 0;
    list-style: none;
}
.terms-conditions-reseller ul li {
    display: inline-block;
    width: auto;
    margin: 0;
    padding: 0;
    vertical-align: middle;
}
.terms-conditions-reseller ul li:last-of-type {
    margin-left: 15px;
}
.terms-conditions-reseller ul li .k-widget,
.terms-conditions-reseller ul li .k-label {
    display: inline-block;
    width: auto;
    margin: 0;
    padding: 0;
    vertical-align: middle;
}
.terms-conditions-reseller ul li .k-widget {
    min-width: 22px;
}
.terms-conditions-reseller ul li .k-widget .radio-icon {
    top: 0;
}
.terms-conditions-reseller ul li .k-widget .error-icon {
    top: 11px;
    left: 100px;
}
.terms-conditions-reseller ul li .k-label {
    padding-top: 3px;
    padding-left: 5px;
}

.guarantee-section {
    padding-left: 24px;
}

#summarization {
    overflow: hidden;
    padding: 0;
}

.summarization-popup {
    width: calc(100% - 20px);
    max-width: calc(100% - 20px);
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
}
.summarization-popup.k-window-maximized {
    max-width: 100%;
}

    .summarization-popup .k-window-titlebar {
        margin-bottom: 0;
    }

.ui-customer-attributes-container {
    margin-bottom: 15px;
}

.ui-customer-attributes-container .k-multiselect .k-select .k-icon:hover {
    color: var(--common-black) !important;
}


/* Sponsor change invite window */
.sponsor-change-invite-content .continue-button {
    margin: 30px auto 0 auto;
    text-align: center;
}

.sponsor-change-invite-content .continue-button button {
    width: 100%;
}

.sponsor-change-invite-content .error {
    margin: 10px auto 0 auto;
    text-align: center;
    color: #B80F0A;
}

/* Summarization of registration */
.registration-summarization {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    padding: 20px 0 0;
    overflow: hidden;
}

.summarization-popup .k-window-titlebar.k-header {
    padding-top: 30px;
}

.summarization-popup .k-window-title {
    font-size: var(--font-size-h5);
    font-weight: 700;
    text-transform: none;
}

.registration-summarization__buttons {
    display: flex;
    flex-direction: column;
padding-right: 30px;
padding-left: 30px;
    margin-top: 20px;
    margin-bottom: 30px;
}
.registration-summarization__button {
    margin-bottom: 20px;
}
.registration-summarization__button:last-child {
    margin-bottom: initial;
}

.registration-summarization .w-buttons {
    margin-top: 15px;
    float: none;
}

.registration-summarization .w-buttons .k-button {
    width: calc(50% - 5px);
}

.registration-summarization__item {
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    flex-wrap: wrap;
    padding: 15px 30px;
    border-bottom: 1px solid #F5F5F5;
}

.registration-summarization__item:last-child {
    border-bottom: none;
}

.registration-summarization__item-sponsor {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.registration-summarization__terms-and-conditions {
    display: none;
}

.registration-summarization__item-sponsor-icon {
    color: #2774AE;
    font-size: var(--font-size-icon9);
    margin-top: 20px;
}

.registration-summarization__item-sponsor-text {
    color: #2774AE;
    margin-top: -5px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
}

.registration-summarization__item .k-checkbox.no-label {
    min-width: 20px;
}

.registration-summarization__title {
    font-weight: bold;
    margin-right: auto;
}


    .registration-box-invite p{
        margin: auto;
        text-align: center;
    }

    .invite-stepper-box {
        display: flex;
        justify-content: space-around;
        max-width: 750px;
        margin: auto;
        padding: 35px;
    }

    .invite-stepper__item-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width:50px;
    }

    .invite-stepper__line {
        width: 100%;
        content: '';
        border-radius: 5px;
        background-color: #0B7B86;
        display: block;
        height: 5px;
        top: 18px;
        padding-left: 10px;
        position: relative;
        margin-left: 10px;
        margin-right: 10px;
    }

    .invite-stepper__icon {
        font-size: var(--font-size-icon6);
        width: 42px;
        height: 42px;
        color: white;
        background-color: #0B7B86;
        border-radius: 50%;
    }

    .invite-stepper__label {
        text-align: center;
        font-weight: 700;
        width: 140px;
    }

@media only screen and (max-width: 550px) {

    .invite-stepper-box {
        flex-direction: column;
        padding: 35px 0 35px 0;
    }

    .invite-stepper__item-wrapper {
        width: initial;
        flex-direction: row;
    }

    .invite-stepper__line {
        width: 5px;
        height: 30px;
        top: initial;
        padding-left: initial;
        padding-right: initial;
        left: 18px;
        margin: 10px 0;
    }

    .registration-box-invite {
        margin-bottom: 0;
    }

    .invite-stepper__label {
        width: initial;
        margin-left: 10px;
    }

    .invite-stepper__icon {
        min-width: 42px;
    }
}

@media only screen and (min-width: 480px) {
    .registration-summarization__item {
        flex-direction: row;
    }

    .registration-summarization__terms-and-conditions {
        display: initial;
    }

    .registration-summarization__item-sponsor-icon {
        margin-top: 30px;
    }
}

@media only screen and (min-width: 620px) {
    .summarization-popup {
        width: 600px;
    }

    .registration-summarization__item > div {
        width: 48%;
    }

    .registration-summarization .w-buttons {
        align-self: flex-end;
        margin-top: 20px;
    }
        .registration-summarization .w-buttons .k-button {
            width: auto;
        }
}

/* Fix for wrong rounding of widths in IE in Invitation Tool*/
@media only screen and (min-width: 1025px) and (-ms-high-contrast: active), (min-width: 1025px) and (-ms-high-contrast: none) {
    .ie-rounding-fix {
        padding-right: 1px;
    }
}
/* Fix for IE adding default width to img elements without src attribute */
@media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .profile-photo-image > img[src=""] {
        width: 0;
    }
}

/* Fix birthday dropdown picker for Edge */
@supports (-ms-ime-align: auto) {
    .k-dropdown-datepicker .k-year {
        margin-left: 4px;
    }
}
